import React from "react";
import { getFormattedDate } from "../../../utilities/getFormattedDate";
import getStatus from "../../../utilities/getStatus";
import Td from "./Td";

const UserInformations = ({ data, user, className }) => {
  return (
    <div className={className || "job-information"}>
      {data.map((el) =>
        user.map((item, key) => {
          if (el.code === item[0]) {
            return (
              <div className="job-information-content" key={key}>
                <Td description={el.label} />
                <Td
                  text={
                    item[1] == null ? (
                      "---"
                    ) : item[0] === "birthday" ? (
                      getFormattedDate(new Date(item[1]), ".")
                    ) : typeof item[1] === "object" ? (
                      item[1]?.name
                    ) : item[0] === "status" ? (
                      <Td
                        text={item[1]}
                        className={getStatus(item[1]).className}
                      ></Td>
                    ) : item[0] === "phone" ? (
                      item[1]
                    ) : typeof item[1] === "number" ? (
                      item[1].toFixed(2)
                    ) : (
                      item[1]
                    )
                  }
                />
              </div>
            );
          }
        })
      )}
    </div>
  );
};

export default UserInformations;
